.pagination {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.pagination__prev {
  margin-right: 4px;
  flex-shrink: 0;
}

.pagination__next {
  margin-left: 4px;
  flex-shrink: 0;
}

.pagination__button {
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid #b9bbc6;
  border-radius: 8px;
  color: var(--blue);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.pagination__button a {
  padding: 12px 11px;
}

.pagination__button--active {
  background-color: var(--blue);
  color: var(--white);
}
.pagination__hidden,
.pagination__show {
  display: none;
}

.pagination__disabled:nth-child(n + 6):nth-child(-n + 10) {
  color: gray;
  background-color: #f0f0f0;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
/* Стили для уведомлений Toast */
.ToastRoot {
  background-color: #333;
  color: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ToastTitle {
  font-weight: bold;
}

.ToastDescription {
  font-size: 14px;
}

.ToastClose {
  background: transparent;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.ToastViewport {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
}
