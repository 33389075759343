.basket {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #d2deff;
  stroke: var(--blue);
}
