.videos {
  padding: 5px 0 64px;
}

.videos__inner {
  margin-top: 24px;
  position: relative;
}

.videos__horizontal {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
  flex-grow: 1;
}

.videos__horizontal.videos__filter-active {
  grid-template-columns: repeat(5, 1fr);
}

.videos__vertical {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  flex-grow: 1;
}

.videos__vertical.videos__filter-active {
  grid-template-columns: repeat(3, 1fr);
}

.videos__mix {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.videos__content {
  display: flex;
  gap: 0 9px;
}

/* RangeSlider.css */
.range-indicators {
  position: absolute;
  top: 5px;
  transform: translateY(-100%);
  width: 100%;
  height: 100%;
}

.range-indicator {
  position: absolute;
  bottom: 0;
  border-radius: 109.01px;
  width: 4px;
  max-height: 64px;
  background: rgb(240, 240, 243);
}

@media (max-width: 1100px) {
  .videos__horizontal {
    grid-template-columns: repeat(4, 1fr);
  }

  .videos__horizontal.videos__filter-active {
    grid-template-columns: repeat(2, 1fr);
  }

  .videos__vertical {
    grid-template-columns: repeat(3, 1fr);
  }

  .videos__vertical.videos__filter-active {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .videos__horizontal {
    grid-template-columns: repeat(3, 1fr);
  }

  .videos__vertical {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .videos {
    padding: 10px 0 32px;
  }

  .videos__inner {
    margin-top: 20px;
  }

  .videos__horizontal {
    grid-template-columns: repeat(2, 1fr);
  }

  .videos__vertical {
    grid-template-columns: 1fr;
  }
}
