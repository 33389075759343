/* roboto-300 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto/roboto-v32-cyrillic_latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-regular - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto/roboto-v32-cyrillic_latin-regular.woff2')
    format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/roboto/roboto-v32-cyrillic_latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/roboto/roboto-v32-cyrillic_latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-900 - cyrillic_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/roboto/roboto-v32-cyrillic_latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-family: 'SF';
  src:
    url(./fonts/sf/SFProDisplay-Bold.woff2) format('woff2'),
    url(./fonts/sf/SFProDisplay-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF';
  src:
    url(./fonts/sf/SFProDisplay-Semibold.woff2) format('woff2'),
    url(./fonts/sf/SFProDisplay-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF';
  src:
    url(./fonts/sf/SFProDisplay-Medium.woff2) format('woff2'),
    url(./fonts/sf/SFProDisplay-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF';
  src:
    url(./fonts/sf/SFProDisplay-Regular.woff2) format('woff2'),
    url(./fonts/sf/SFProDisplay-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
