/* Стили для пагинации только внутри blogSwiper
.blogSwiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #1F2D5C;
  border-radius: 90px;
}
.blogSwiper .swiper-pagination {
    bottom:47px;
    left: 24px !important;
    z-index: 1000;
}
.blogSwiper .swiper-pagination-bullet-active {
  width: 24px;
  height: 8px;
  background-color: #1F2D5C;
} */
.blogSwiper {
  width: 100%;
  box-sizing: border-box;
}

.swiper-slide {
  width: 100%;
}

